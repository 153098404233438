import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Button } from 'grommet';
import { FormNextLink } from 'grommet-icons';
import ImgixImage from './ImgixImage';

const ModulePressItem = ({
  title,
  publicationName,
  publicationLogo,
  link,
  layout,
  border,
  borderColor,
  borderGlow,
  pad = 'xlarge',
  ...rest
}) => {
  const borders = border.map((x) => ({
    side: x,
    size: 'small',
    color: borderColor ? borderColor.color : 'brand',
  }));
  return layout === 'stacked' ? (
    <Box
      style={
        borderGlow
          ? {
              boxShadow: `0px 0 4px ${borderColor.color}, inset 0 0 4px ${borderColor.color}`,
            }
          : rest.style || {}
      }
      border={borders}
      justify="center"
      align="center"
      fill
      pad={pad}
      gap="medium"
    >
      {publicationLogo && (
        <Box flex={false} width={{ max: '35%' }}>
          <ImgixImage
            srcSetOptions={{ w: 200 }}
            src={publicationLogo.filename}
            lazy
          />
        </Box>
      )}
      <Heading textAlign="center" level={4} style={{ textTransform: 'none' }}>
        {title}
      </Heading>
    </Box>
  ) : (
    <Box pad={pad} border={borders} {...rest}>
      <Heading level={4} style={{ textTransform: 'none' }}>
        {title}
      </Heading>
      <Box align="start" margin={{ top: 'medium' }}>
        <Button
          icon={<FormNextLink />}
          fill={false}
          width="auto"
          label={publicationName}
          href={link.url}
          style={{ padding: 0 }}
          reverse
        />
      </Box>
    </Box>
  );
};

ModulePressItem.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  publicationName: PropTypes.string,
  publicationLogo: PropTypes.object,
  link: PropTypes.object,
  layout: PropTypes.oneOf(['text', 'stacked']),
  border: PropTypes.array,
  borderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  borderGlow: PropTypes.bool,
  pad: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
};

export default memo(ModulePressItem);
