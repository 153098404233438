import React from 'react';
import PropTypes from 'prop-types';

import ModulePressItem from '../ModulePressItem';

const DynamicPressItem = ({ blok }) => <ModulePressItem {...blok} />;

DynamicPressItem.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicPressItem;
